import { ReactNode, RefObject } from "react";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Button, { ButtonProps } from "react-bootstrap/Button";

export type BaseModalProps = ModalProps & {
	title?: ReactNode;
	header?: ReactNode;
	children?: ReactNode;
	footer?: ReactNode;
	modalBodyRef?: RefObject<HTMLDivElement>;
	modalBodyProps?: Object;
	actionButtonProps?: ButtonProps;
};

export function BaseModal({
	title,
	header,
	children,
	footer,
	modalBodyRef,
	modalBodyProps,
	actionButtonProps,
	...rest
}: BaseModalProps) {
	return (
		<Modal {...rest}>
			{header ? (
				header
			) : (
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
			)}

			<Modal.Body className="base-modal-body" ref={modalBodyRef} {...modalBodyProps}>
				{children}
			</Modal.Body>

			{footer ? (
				footer
			) : (
				<Modal.Footer className="d-flex justify-content-end">
					<Button variant="light" onClick={rest.onHide}>
						Fechar
					</Button>
				</Modal.Footer>
			)}
		</Modal>
	);
}
